@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
body {font-family: 'Roboto', sans-serif;background-color: #464243;}




.Title {
    @apply text-center relative before:content-[attr(before)] before:pointer-events-none before:text-9xl before:font-semibold before:text-white before:opacity-5 mb-10 before:max-lg:text-8xl before:max-sm:hidden;
}
.Title .T {
    @apply sm:absolute sm:top-1/2 sm:left-1/2 transform sm:-translate-x-1/2 sm:-translate-y-1/2 text-4xl max-sm:text-3xl text-white font-medium pb-3 before:absolute before:bottom-0 before:left-1/2 before:-translate-x-1/2 before:w-20 before:h-0.5 before:bg-[#ffcd57];
}

.menuItem {
    @apply cursor-pointer text-white font-medium hover:opacity-80 pt-2 pb-2 relative max-sm:text-black;
}
.menuItem.active{
    @apply max-sm:text-[#ffcd57] sm:before:absolute sm:before:bottom-0 sm:before:left-1/2 sm:before:-translate-x-1/2 sm:before:w-full sm:before:bg-white sm:before:h-0.5 sm:before:bg-[#ffcd57];
}
